<template>

  <div v-if="this.$store.state.fetchedProjekte && slides" class="">

    <carousel :items-to-show="1.5" :touch-drag="true" :wrap-around="true" :breakpoints="breakpoints">

      <slide v-for="slide in slides" :key="slide">

        <div class="mx-4">

          <img :srcset="slide.large_srcset"  sizes="" alt="">

          <p v-if="slide.caption">{{slide.caption}}</p>

          <p class="invisible" v-else> P</p>

        </div>

      </slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>

    </carousel>

  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 200px and up
      200: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      700: {
        itemsToShow: 1.2,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1.5,
        snapAlign: 'center',
      },
    },
  }),

  computed: {

    slides() {

      // let fotos = ['https://zweizehn.nickschnee.ch/wp-content/uploads/2021/10/7-Cowboys-1-scaled.jpg', 'https://zweizehn.nickschnee.ch/wp-content/uploads/2021/10/Dementia-2-scaled.jpg']

      var shorturl = this.$route.params.shorturl;

      var projekte = this.$store.state.projekte;

      var result = projekte.filter(obj => {
        return obj.shorturl == shorturl;
      })

      console.log(result[0].galerie);

      if (result[0].galerie){

        return result[0].galerie;

      } else {


        return false;
      }


    },

  },

};
</script>

<style>

:root {

  --vc-clr-primary: white;
  --vc-clr-secondary: #28303D;
  --vc-clr-white: black;
  --vc-nav-color: black;

}


</style>
