<template>
  <div v-if="this.$store.state.fetchedProjekte" class="home bg-black pb-16 p-4">

    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <Header> </Header>

    <div class="w-full lg:w-2/3 mx-auto">

      <h2 class="text-left text-4xl md:text-6xl">{{projekt.titel}}</h2>

      <p class="border-l-2 px-4 mb-4" v-html="projekt.lead"> </p>

      <p class=" px-4 mb-16" v-html="projekt.inhalt"> </p>

      <Carousel> </Carousel>

      <p class="underline cursor-pointer" @click="$router.go(-1)"> zurück </p>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Carousel from '@/components/Carousel.vue'


export default {
  name: 'Projekt_Page',
  components: {
    Header,
    Carousel

  },

  computed: {

    projekt() {

      var shorturl = this.$route.params.shorturl;

      var projekte = this.$store.state.projekte;

      var result = projekte.filter(obj => {
        return obj.shorturl == shorturl;
      })

      return result[0];

    },

  },

  methods: {

    onload: function () {
      // console.log("hello");
      this.$store.dispatch('initialFetch');

    }

  },

  mounted: function(){

    this.onload();

  },


}
</script>
